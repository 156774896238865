/*export const myMutation = ({commit})=>{}*/

export const setCategories = (state, categories) => {
    state.categories = [...state.categories, ...categories]
    state.isLoading = false
}

export const setItems = (state, items) => {
    state.items = [...state.items, ...items]
    state.isLoading = false
}

export const setItem = (state, item) => {
    state.item = item
}

export const addUser = (state, user) => {
    state.users = [user, ...state.users]
}

export const deleteUser = (state, user_id) => {
    state.users = state.users.filter(user => user.user_id !== user_id)
}

export const updateUser = (state, user) => {
    const idx = state.users.map(e => e.user_id).indexOf(user.user_id)
    state.users[idx] = user
}

export const setCurrentUser = (state, user) => {
    state.currentUser = user
}

export const setActiveUserId = (state, user_id) => {
    state.activeUserId = user_id
}

export const reset = (state) => {
    state.isLoading = true
    state.users = []
    state.currentUser = {}
    state.activeUserId = 0
}
