/*export const myGetter = (state)=>{return state}*/

export const getIsLoading = (state) => () => {
    return state.isLoading
}

export const getUsersByTerm = (state) => (term = '') => {
    if (term.length === 0) return state.users
    return state.users.filter(user => (user.name + ' ' + user.surname).toLowerCase().includes(term.toLowerCase()))
}

export const getItemByItemId = (state) => (item_id = '') => {
    const item = state.items.find(item => item.item_id === item_id)
    if (!item) return
    return {...item}
}

export const getCurrentUser = (state) => () => {
    return state.currentUser
}

export const getItems = (state) => () => {
    return state.items
}


export const getItem = (state) => () => {
    return state.item
}
